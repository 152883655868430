/* tslint:disable */
/* eslint-disable */
/**
 * HARUTO COATIONG API
 * HARUTO COATIONG API Interface
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Model400 } from '../model';
// @ts-ignore
import { Model500 } from '../model';
// @ts-ignore
import { NewCsvDownloadUrlRequest } from '../model';
// @ts-ignore
import { NewCsvDownloadUrlResponse } from '../model';
// @ts-ignore
import { SearchInsuranceContractorResponse } from '../model';
// @ts-ignore
import { UpdateInsuranceContractorsMemoRequest } from '../model';
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定保険契約者をキャンセル状態にする 
         * @summary 指定保険契約者をキャンセル状態にする
         * @param {string} insuranceContractorID キャンセル対象の保険契約者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInsuranceContractor: async (insuranceContractorID: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceContractorID' is not null or undefined
            assertParamExists('cancelInsuranceContractor', 'insuranceContractorID', insuranceContractorID)
            const localVarPath = `/admin/insurance-contractors/{insuranceContractorID}/cancel`
                .replace(`{${"insuranceContractorID"}}`, encodeURIComponent(String(insuranceContractorID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した年月に登録された保険契約者一覧のcsvファイルのurlを発行する 
         * @summary 指定した年月に登録された保険契約者一覧のcsvファイルのurlを発行する
         * @param {NewCsvDownloadUrlRequest} [newCsvDownloadUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCsvDownloadUrl: async (newCsvDownloadUrlRequest?: NewCsvDownloadUrlRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/insurance-contractors/new-csv-download-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newCsvDownloadUrlRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保険契約者の一覧を指定条件でフィルタリングして取得する。ページングなし。 
         * @summary 保険契約者の一覧を取得する
         * @param {string} [name] 検索対象氏名
         * @param {string} [kana] 検索対象フリガナ
         * @param {string} [storeName] 登録店舗名
         * @param {string} [phoneNumber] 検索対象電話番号ハイフンなし
         * @param {number} [page] 取得対象ページ
         * @param {number} [pageSize] ページサイズ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsuranceContractor: async (name?: string, kana?: string, storeName?: string, phoneNumber?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/insurance-contractors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (kana !== undefined) {
                localVarQueryParameter['kana'] = kana;
            }

            if (storeName !== undefined) {
                localVarQueryParameter['store_name'] = storeName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page-size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定保険契約者のメモを更新する 
         * @summary 指定保険契約者のメモを更新する
         * @param {string} insuranceContractorID メモ更新対象の保険契約者ID
         * @param {UpdateInsuranceContractorsMemoRequest} [updateInsuranceContractorsMemoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsuranceContractorsMemo: async (insuranceContractorID: string, updateInsuranceContractorsMemoRequest?: UpdateInsuranceContractorsMemoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceContractorID' is not null or undefined
            assertParamExists('updateInsuranceContractorsMemo', 'insuranceContractorID', insuranceContractorID)
            const localVarPath = `/admin/insurance-contractors/{insuranceContractorID}/memo`
                .replace(`{${"insuranceContractorID"}}`, encodeURIComponent(String(insuranceContractorID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInsuranceContractorsMemoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定保険契約者をキャンセル状態にする 
         * @summary 指定保険契約者をキャンセル状態にする
         * @param {string} insuranceContractorID キャンセル対象の保険契約者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelInsuranceContractor(insuranceContractorID: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelInsuranceContractor(insuranceContractorID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した年月に登録された保険契約者一覧のcsvファイルのurlを発行する 
         * @summary 指定した年月に登録された保険契約者一覧のcsvファイルのurlを発行する
         * @param {NewCsvDownloadUrlRequest} [newCsvDownloadUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newCsvDownloadUrl(newCsvDownloadUrlRequest?: NewCsvDownloadUrlRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewCsvDownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newCsvDownloadUrl(newCsvDownloadUrlRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保険契約者の一覧を指定条件でフィルタリングして取得する。ページングなし。 
         * @summary 保険契約者の一覧を取得する
         * @param {string} [name] 検索対象氏名
         * @param {string} [kana] 検索対象フリガナ
         * @param {string} [storeName] 登録店舗名
         * @param {string} [phoneNumber] 検索対象電話番号ハイフンなし
         * @param {number} [page] 取得対象ページ
         * @param {number} [pageSize] ページサイズ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchInsuranceContractor(name?: string, kana?: string, storeName?: string, phoneNumber?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchInsuranceContractorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchInsuranceContractor(name, kana, storeName, phoneNumber, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定保険契約者のメモを更新する 
         * @summary 指定保険契約者のメモを更新する
         * @param {string} insuranceContractorID メモ更新対象の保険契約者ID
         * @param {UpdateInsuranceContractorsMemoRequest} [updateInsuranceContractorsMemoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInsuranceContractorsMemo(insuranceContractorID: string, updateInsuranceContractorsMemoRequest?: UpdateInsuranceContractorsMemoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInsuranceContractorsMemo(insuranceContractorID, updateInsuranceContractorsMemoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 指定保険契約者をキャンセル状態にする 
         * @summary 指定保険契約者をキャンセル状態にする
         * @param {string} insuranceContractorID キャンセル対象の保険契約者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelInsuranceContractor(insuranceContractorID: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelInsuranceContractor(insuranceContractorID, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した年月に登録された保険契約者一覧のcsvファイルのurlを発行する 
         * @summary 指定した年月に登録された保険契約者一覧のcsvファイルのurlを発行する
         * @param {NewCsvDownloadUrlRequest} [newCsvDownloadUrlRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newCsvDownloadUrl(newCsvDownloadUrlRequest?: NewCsvDownloadUrlRequest, options?: any): AxiosPromise<NewCsvDownloadUrlResponse> {
            return localVarFp.newCsvDownloadUrl(newCsvDownloadUrlRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 保険契約者の一覧を指定条件でフィルタリングして取得する。ページングなし。 
         * @summary 保険契約者の一覧を取得する
         * @param {string} [name] 検索対象氏名
         * @param {string} [kana] 検索対象フリガナ
         * @param {string} [storeName] 登録店舗名
         * @param {string} [phoneNumber] 検索対象電話番号ハイフンなし
         * @param {number} [page] 取得対象ページ
         * @param {number} [pageSize] ページサイズ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInsuranceContractor(name?: string, kana?: string, storeName?: string, phoneNumber?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<SearchInsuranceContractorResponse> {
            return localVarFp.searchInsuranceContractor(name, kana, storeName, phoneNumber, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定保険契約者のメモを更新する 
         * @summary 指定保険契約者のメモを更新する
         * @param {string} insuranceContractorID メモ更新対象の保険契約者ID
         * @param {UpdateInsuranceContractorsMemoRequest} [updateInsuranceContractorsMemoRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInsuranceContractorsMemo(insuranceContractorID: string, updateInsuranceContractorsMemoRequest?: UpdateInsuranceContractorsMemoRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateInsuranceContractorsMemo(insuranceContractorID, updateInsuranceContractorsMemoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 指定保険契約者をキャンセル状態にする 
     * @summary 指定保険契約者をキャンセル状態にする
     * @param {string} insuranceContractorID キャンセル対象の保険契約者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public cancelInsuranceContractor(insuranceContractorID: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).cancelInsuranceContractor(insuranceContractorID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した年月に登録された保険契約者一覧のcsvファイルのurlを発行する 
     * @summary 指定した年月に登録された保険契約者一覧のcsvファイルのurlを発行する
     * @param {NewCsvDownloadUrlRequest} [newCsvDownloadUrlRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public newCsvDownloadUrl(newCsvDownloadUrlRequest?: NewCsvDownloadUrlRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).newCsvDownloadUrl(newCsvDownloadUrlRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保険契約者の一覧を指定条件でフィルタリングして取得する。ページングなし。 
     * @summary 保険契約者の一覧を取得する
     * @param {string} [name] 検索対象氏名
     * @param {string} [kana] 検索対象フリガナ
     * @param {string} [storeName] 登録店舗名
     * @param {string} [phoneNumber] 検索対象電話番号ハイフンなし
     * @param {number} [page] 取得対象ページ
     * @param {number} [pageSize] ページサイズ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public searchInsuranceContractor(name?: string, kana?: string, storeName?: string, phoneNumber?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).searchInsuranceContractor(name, kana, storeName, phoneNumber, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定保険契約者のメモを更新する 
     * @summary 指定保険契約者のメモを更新する
     * @param {string} insuranceContractorID メモ更新対象の保険契約者ID
     * @param {UpdateInsuranceContractorsMemoRequest} [updateInsuranceContractorsMemoRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updateInsuranceContractorsMemo(insuranceContractorID: string, updateInsuranceContractorsMemoRequest?: UpdateInsuranceContractorsMemoRequest, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updateInsuranceContractorsMemo(insuranceContractorID, updateInsuranceContractorsMemoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
