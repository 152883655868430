import { Token } from "@/models/token";
import { AuthRepository } from "@/repository/auth_repository";
import { AuthState } from "@/store/auth_state_store";
import ApiBase from "./api_base";
import { Config } from "./config";

export default class APIAuthRepository extends ApiBase implements AuthRepository {
  constructor(authState: AuthState, config: Config) {
    super(authState, config);
  }

  async auth(id: string, password: string): Promise<Token> {
    const api = this.newUserClient();
    const res = await api.oauth({id, password})
    this.authState.auth = true;
    return {
      accessToken: res.data.token.access_token,
      refreshToken: res.data.token.refresh_token,
      expiresAt: res.data.token.expires_at,
    }
  }

  async passwordResetConfirmation(id: string): Promise<void> {
    const api = this.newUserClient();
    await api.sendPasswordConfirmation({id})
    return;
  }

  async resetPassword(password: string, resetToken: string): Promise<void> {
    const api = this.newUserClient();
    await api.resetPassword({new_password: password, reset_token: resetToken});
    return;
  }
}
