import { reactive } from "vue";

export type AuthState = {
  auth: boolean;
}

export let authState: AuthState | undefined = undefined;

export const initAuthState = (auth: boolean): void => {
  const state = reactive({
    auth,
  });

  authState = {
    get auth(): boolean {
      return state.auth;
    },
    set auth(v: boolean) {
      state.auth = v;
    },
  }
};

