/* tslint:disable */
/* eslint-disable */
/**
 * HARUTO COATIONG API
 * HARUTO COATIONG API Interface
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetMyInsuranceContractorResponse } from '../model';
// @ts-ignore
import { Model400 } from '../model';
// @ts-ignore
import { Model401 } from '../model';
// @ts-ignore
import { Model500 } from '../model';
// @ts-ignore
import { NewInsuranceContractorRequest } from '../model';
// @ts-ignore
import { NewUploadImageUrlsRequest } from '../model';
// @ts-ignore
import { NewUploadImageUrlsResponse } from '../model';
// @ts-ignore
import { OauthRequest } from '../model';
// @ts-ignore
import { OauthResponse } from '../model';
// @ts-ignore
import { RefreshRequest } from '../model';
// @ts-ignore
import { RefreshResponse } from '../model';
// @ts-ignore
import { ResetPasswordRequest } from '../model';
// @ts-ignore
import { SendPasswordResetConfirmationRequest } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログインユーザーの保険契約者としての情報を取得します 
         * @summary ログインユーザーの保険契約者としての情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInsuranceContractor: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/insurance-contractor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 契約者登録=ユーザー登録となります。 登録完了するとユーザーへ完了の旨のメールが送信されます。 
         * @summary 保険契約者登録を行う
         * @param {NewInsuranceContractorRequest} [newInsuranceContractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newInsuranceContractor: async (newInsuranceContractorRequest?: NewInsuranceContractorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/insurance-contractors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newInsuranceContractorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 写真アップロード用のurlを発行します。 アップロード予定の画像のサイズと拡張子を指定することで特定の画像のみアップロード可能な urlを払い出します 
         * @summary 写真アップロード用のurlを発行する
         * @param {NewUploadImageUrlsRequest} [newUploadImageUrlsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUploadImageUrls: async (newUploadImageUrlsRequest?: NewUploadImageUrlsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/upload-image-urls/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUploadImageUrlsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしてトークンを発行します 
         * @summary ログインしてトークンを発行します
         * @param {OauthRequest} [oauthRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth: async (oauthRequest?: OauthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/oauth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oauthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインしてアクセストークンを発行します 
         * @summary リフレッシュトークンを利用してトークンをrefreshします
         * @param {RefreshRequest} [refreshRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (refreshRequest?: RefreshRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/oauth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワードを再設定します。再設定のための確認メールに 
         * @summary パスワードを再設定します
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest?: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワード再設定のための確認メールを送信します。送信される確認メールのリンクからパスワードの再設定が可能になります。 
         * @summary パスワード再設定のための確認メールを送信します
         * @param {SendPasswordResetConfirmationRequest} [sendPasswordResetConfirmationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordConfirmation: async (sendPasswordResetConfirmationRequest?: SendPasswordResetConfirmationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/password/send_confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendPasswordResetConfirmationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * ログインユーザーの保険契約者としての情報を取得します 
         * @summary ログインユーザーの保険契約者としての情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyInsuranceContractor(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMyInsuranceContractorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyInsuranceContractor(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 契約者登録=ユーザー登録となります。 登録完了するとユーザーへ完了の旨のメールが送信されます。 
         * @summary 保険契約者登録を行う
         * @param {NewInsuranceContractorRequest} [newInsuranceContractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newInsuranceContractor(newInsuranceContractorRequest?: NewInsuranceContractorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newInsuranceContractor(newInsuranceContractorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 写真アップロード用のurlを発行します。 アップロード予定の画像のサイズと拡張子を指定することで特定の画像のみアップロード可能な urlを払い出します 
         * @summary 写真アップロード用のurlを発行する
         * @param {NewUploadImageUrlsRequest} [newUploadImageUrlsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newUploadImageUrls(newUploadImageUrlsRequest?: NewUploadImageUrlsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewUploadImageUrlsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newUploadImageUrls(newUploadImageUrlsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしてトークンを発行します 
         * @summary ログインしてトークンを発行します
         * @param {OauthRequest} [oauthRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oauth(oauthRequest?: OauthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oauth(oauthRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインしてアクセストークンを発行します 
         * @summary リフレッシュトークンを利用してトークンをrefreshします
         * @param {RefreshRequest} [refreshRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(refreshRequest?: RefreshRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(refreshRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワードを再設定します。再設定のための確認メールに 
         * @summary パスワードを再設定します
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワード再設定のための確認メールを送信します。送信される確認メールのリンクからパスワードの再設定が可能になります。 
         * @summary パスワード再設定のための確認メールを送信します
         * @param {SendPasswordResetConfirmationRequest} [sendPasswordResetConfirmationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPasswordConfirmation(sendPasswordResetConfirmationRequest?: SendPasswordResetConfirmationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendPasswordConfirmation(sendPasswordResetConfirmationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * ログインユーザーの保険契約者としての情報を取得します 
         * @summary ログインユーザーの保険契約者としての情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyInsuranceContractor(options?: any): AxiosPromise<GetMyInsuranceContractorResponse> {
            return localVarFp.getMyInsuranceContractor(options).then((request) => request(axios, basePath));
        },
        /**
         * 契約者登録=ユーザー登録となります。 登録完了するとユーザーへ完了の旨のメールが送信されます。 
         * @summary 保険契約者登録を行う
         * @param {NewInsuranceContractorRequest} [newInsuranceContractorRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newInsuranceContractor(newInsuranceContractorRequest?: NewInsuranceContractorRequest, options?: any): AxiosPromise<void> {
            return localVarFp.newInsuranceContractor(newInsuranceContractorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 写真アップロード用のurlを発行します。 アップロード予定の画像のサイズと拡張子を指定することで特定の画像のみアップロード可能な urlを払い出します 
         * @summary 写真アップロード用のurlを発行する
         * @param {NewUploadImageUrlsRequest} [newUploadImageUrlsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUploadImageUrls(newUploadImageUrlsRequest?: NewUploadImageUrlsRequest, options?: any): AxiosPromise<NewUploadImageUrlsResponse> {
            return localVarFp.newUploadImageUrls(newUploadImageUrlsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしてトークンを発行します 
         * @summary ログインしてトークンを発行します
         * @param {OauthRequest} [oauthRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth(oauthRequest?: OauthRequest, options?: any): AxiosPromise<OauthResponse> {
            return localVarFp.oauth(oauthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * ログインしてアクセストークンを発行します 
         * @summary リフレッシュトークンを利用してトークンをrefreshします
         * @param {RefreshRequest} [refreshRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(refreshRequest?: RefreshRequest, options?: any): AxiosPromise<RefreshResponse> {
            return localVarFp.refresh(refreshRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワードを再設定します。再設定のための確認メールに 
         * @summary パスワードを再設定します
         * @param {ResetPasswordRequest} [resetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワード再設定のための確認メールを送信します。送信される確認メールのリンクからパスワードの再設定が可能になります。 
         * @summary パスワード再設定のための確認メールを送信します
         * @param {SendPasswordResetConfirmationRequest} [sendPasswordResetConfirmationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordConfirmation(sendPasswordResetConfirmationRequest?: SendPasswordResetConfirmationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.sendPasswordConfirmation(sendPasswordResetConfirmationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * ログインユーザーの保険契約者としての情報を取得します 
     * @summary ログインユーザーの保険契約者としての情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMyInsuranceContractor(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getMyInsuranceContractor(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 契約者登録=ユーザー登録となります。 登録完了するとユーザーへ完了の旨のメールが送信されます。 
     * @summary 保険契約者登録を行う
     * @param {NewInsuranceContractorRequest} [newInsuranceContractorRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public newInsuranceContractor(newInsuranceContractorRequest?: NewInsuranceContractorRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).newInsuranceContractor(newInsuranceContractorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 写真アップロード用のurlを発行します。 アップロード予定の画像のサイズと拡張子を指定することで特定の画像のみアップロード可能な urlを払い出します 
     * @summary 写真アップロード用のurlを発行する
     * @param {NewUploadImageUrlsRequest} [newUploadImageUrlsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public newUploadImageUrls(newUploadImageUrlsRequest?: NewUploadImageUrlsRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).newUploadImageUrls(newUploadImageUrlsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしてトークンを発行します 
     * @summary ログインしてトークンを発行します
     * @param {OauthRequest} [oauthRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public oauth(oauthRequest?: OauthRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).oauth(oauthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインしてアクセストークンを発行します 
     * @summary リフレッシュトークンを利用してトークンをrefreshします
     * @param {RefreshRequest} [refreshRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public refresh(refreshRequest?: RefreshRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).refresh(refreshRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワードを再設定します。再設定のための確認メールに 
     * @summary パスワードを再設定します
     * @param {ResetPasswordRequest} [resetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetPassword(resetPasswordRequest?: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).resetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワード再設定のための確認メールを送信します。送信される確認メールのリンクからパスワードの再設定が可能になります。 
     * @summary パスワード再設定のための確認メールを送信します
     * @param {SendPasswordResetConfirmationRequest} [sendPasswordResetConfirmationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public sendPasswordConfirmation(sendPasswordResetConfirmationRequest?: SendPasswordResetConfirmationRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).sendPasswordConfirmation(sendPasswordResetConfirmationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
