import { authState } from "@/store/auth_state_store";
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  Router,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  // admin
  {
    path: "/admin",
    name: "admin-scaffold",
    component: () =>
      import(
        /* webpackChunkName: "admin-scaffold" */ "../views/admin/Scaffold.vue"
      ),
    children: [
      {
        path: "",
        name: "admin-search",
        component: () =>
          import(
            /* webpackChunkName: "admin-search" */ "../views/admin/Search.vue"
          ),
      },
      {
        path: "csv",
        name: "admin-csv",
        component: () =>
          import(
            /* webpackChunkName: "admin-csv" */ "../views/admin/Csv.vue"
          ),
      },
    ],
  },

  // user
  {
    path: "/",
    name: "user-scaffold",
    component: () =>
      import(
        /* webpackChunkName: "user-scaffold" */ "../views/user/Scaffold.vue"
      ),
    children: [
      {
        path: "",
        name: "user-home",
        meta: { auth: true },
        component: () =>
          import(
            /* webpackChunkName: "user-home" */ "../views/user/Home.vue"
          ),
      },
      {
        path: "/register",
        name: "user-register",
        component: () =>
          import(
            /* webpackChunkName: "user-register" */ "../views/user/Register.vue"
          ),
      },
      {
        path: "/login",
        name: "user-login",
        component: () =>
          import(
            /* webpackChunkName: "user-login" */ "../views/user/Login.vue"
          ),
      },

      {
        path: "/password/reset/confirmation",
        name: "user-password-rest-confirmation",
        component: () =>
          import(
            /* webpackChunkName: "user-password-reset-confirmation" */ "../views/user/ResetPasswordConfirmation.vue"
          ),
      },
      {
        path: "/password/reset",
        name: "user-password-rest",
        component: () =>
          import(
            /* webpackChunkName: "user-password-reset" */ "../views/user/ResetPassword.vue"
          ),
      },
    ],
  },
];

const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const needsAuth = to.meta.auth;
  const auth = authState?.auth ?? false;
  if (needsAuth && !auth) {
    return next({path: "/login"});
  }
  next();
});


export default router;
