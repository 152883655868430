import { AuthState } from "@/store/auth_state_store";
import LocalStorageTokenRepository from "../local-storage/local_storage_token_repository";
import { Config } from "./config";
import { Configuration as StubConfig, UserApi, AdminApi } from "@/infra/ts-client";
import { Token } from "@/models/token";
import { AuthenticationError, TokenNotFoundError, TokenRefreshError } from "@/repository/errors";
import { AxiosError } from "axios";

export const tokenRepository = new LocalStorageTokenRepository();

export default class ApiBase {
  authState: AuthState;
  config: Config;

  constructor(authState: AuthState, config: Config) {
    this.config = config;
    this.authState = authState;
  }

  newAdminClient(): AdminApi {
    return new AdminApi(
      new StubConfig({
        basePath: this.config.baseURL,
      }),
    );
  }

  newUserClient(token?: Token): UserApi {
    return new UserApi(
      new StubConfig({
        basePath: this.config.baseURL,
        accessToken: token?.accessToken,
      }),
    );
  }

  getToken(): Promise<Token> {
    return tokenRepository.get().then((token) => {
      if (token === null) {
        this.authState.auth = false;
        throw new TokenNotFoundError();
      }
      // NOTE: tokenが取り出せたらとりあえずは認証にされていることにする
      //       （access token & refresh tokenが期限切れ = 再ログイン必要の判定は他のapiを叩いた時に実施）
      this.authState.auth = true;
      return token;
    });
  }

  saveToken(token: Token): Promise<void> {
    return tokenRepository.save(token)
      .then(() => {
      this.authState.auth = true;
      return;
    });
  }

  refreshToken(token: Token): Promise<Token> {
    return this.newUserClient()
      .refresh({
        refresh_token: token.refreshToken ?? "",
      })
      .then((resp) => {
        return {
          accessToken: resp.data.token.access_token,
          refreshToken: resp.data.token.refresh_token,
          expiresAt: resp.data.token.expires_at,
        };
      })
      .catch(() => {
        throw new TokenRefreshError();
      });
  }

  retryIfTokenExpired<R>(f: (token: Token) => Promise<R>): Promise<R> {
    return this.getToken().then((token) => {
      return f(token).catch((e) => {

        const status = (e as AxiosError).response?.status;
        if (status === 403) {
          // トークンのリフレッシュを行う。
          return this.refreshToken(token)
            .then((newToken) => {
              return this.saveToken(newToken).then(() => {
                console.log("token refreshed");
                return newToken;
              });
            })
            .then((newToken) => {
              // retry
              return f(newToken);
            });
        }
        return Promise.reject(e);
      });
    });
  }
}
