import { InsuranceContractor } from "@/models/insurance_contractor";
import { InsuranceContractorRepository, SearchParams, SearchRes } from "@/repository/insurance_contractor_repository";
import { AuthState } from "@/store/auth_state_store";
import ApiBase from "./api_base";
import { Config } from "./config";
import * as ApiModel from "@/infra/ts-client/model";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { AxiosError } from "axios";
import { DuplicateValueError } from "@/repository/errors";
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Tokyo')


export default class APIInsuranceContractorRepository extends ApiBase implements InsuranceContractorRepository {
  constructor(authState: AuthState, config: Config) {
    super(authState, config);
  }

  async getSelf(): Promise<InsuranceContractor> {
    return this.retryIfTokenExpired((t) => {
      const api = this.newUserClient(t);
      return api.getMyInsuranceContractor()
    }).then(res => {
      return this.toModel(res.data.insurance_contractor);
    })
  }

  async register(ic: Partial<InsuranceContractor>, password: string, coatingUuid: string, receiptUuid: string): Promise<void> {
    const api = this.newUserClient();
    return api.newInsuranceContractor({
      conversion_id: ic.conversionId ?? "",
      name: ic.name ?? "",
      kana: ic.kana ?? "",
      phone_number: ic.phoneNumber ?? "",
      store_name: ic.storeName ?? "",
      email: ic.email ?? "",
      password,
      address: {
        postal_code: ic.postalCode ?? "",
        prefecture: ic.prefecture ?? "",
        city: ic.city ?? "",
        street: ic.street ?? "",
        building: ic.building ?? "",
      },
      device: {
        name: ic.deviceName ?? "",
        imei: ic.deviceImei ?? "",
      },
      coating_image_uuid: coatingUuid,
      receipt_image_uuid: receiptUuid,
    })
      .then(() => {
        return;
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 409) {
          throw new DuplicateValueError();
        }
        throw e;
      })
  }

  async search(params: SearchParams): Promise<SearchRes> {
    const api = this.newAdminClient();
    const res = await api.searchInsuranceContractor(params.name, params.kana, params.storeName, params.phoneNumber, params.page, params.pageSize);
    const list = res.data.insurance_contractors?.map((ic) => this.toModel(ic)) ?? [];

    return {
      list,
      totalPage: res.data.total_page ?? 1,
      totalNum: res.data.total_num ?? 1,
      currentPage: res.data.current_page ?? 1,
    }
  }

  async cancel(id: string): Promise<void> {
    const api = this.newAdminClient();
    return api.cancelInsuranceContractor(id).then(() => {
      return;
    });
  }

  async updateMemo(ic: InsuranceContractor): Promise<void> {
    const api = this.newAdminClient();
    return api.updateInsuranceContractorsMemo(ic.id ?? "", { memo: ic.memo }).then(() => {
      return;
    });
  }

  async getCsvUrl(yearMonth: string): Promise<string> {
    const api = this.newAdminClient();
    return api.newCsvDownloadUrl({year_month: yearMonth}).then((res) => {
      return res.data.url;
    });
  }

  private toModel(origin: ApiModel.InsuranceContractor): InsuranceContractor {
    const contractedAt = dayjs.unix(origin.contracted_at).format("YYYY-MM-DD HH:mm:ss");

    let canceledAt = undefined;
    if (origin.canceled_at !== undefined) {
      canceledAt = dayjs.unix(origin.canceled_at).format("YYYY-MM-DD HH:mm:ss");
    }
    return {
      id: origin.id,
      conversionId: origin.conversion_id,
      email: origin.email,
      name: origin.name,
      kana: origin.kana,
      storeName: origin.store_name,
      phoneNumber: origin.phone_number,
      postalCode: origin.address.postal_code,
      prefecture: origin.address.prefecture,
      city: origin.address.city,
      street: origin.address.street,
      building: origin.address.building,
      deviceName: origin.device.name,
      deviceImei: origin.device.imei,
      coatingImageUrl: origin.coating_image_url,
      receiptImageUrl: origin.receipt_image_url,
      canceled: origin.canceled,
      memo: origin.memo,
      contractedAt,
      canceledAt,
    };
  }
}
