import { AuthState } from "@/store/auth_state_store";
import ApiBase from "./api_base";
import { Config } from "./config";
import { AxiosError } from "axios";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { UploadImageUrl } from "@/models/upload_image_url";
import { UploadImageUrlRepository } from "@/repository/upload_image_url";
import { FileSizeLimitError } from "@/repository/errors";
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Tokyo')


export default class APIUploadImageUrlRepository extends ApiBase implements UploadImageUrlRepository {
  constructor(authState: AuthState, config: Config) {
    super(authState, config);
  }

  async getUploadImageUrl(coatingSize: number, receiptSize: number): Promise<UploadImageUrl> {
    const api = this.newUserClient();
    return api.newUploadImageUrls({
      coating_image_size: coatingSize,
      receipt_image_size: receiptSize, 
    })
      .then((res) => {
        return {
          coatingUuid: res.data.coating_image_uuid,
          
          coatingImageUrl: res.data.coating_image_url,
          receiptUuid: res.data.receipt_image_uuid,
          receiptImageUrl: res.data.receipt_image_url
        }
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 400) {
          throw new FileSizeLimitError();
        }
        throw e;
      });
  }
}
