import { App, inject, InjectionKey } from 'vue';
import { AuthRepository } from './auth_repository';
import { InsuranceContractorRepository } from './insurance_contractor_repository';
import { TokenRepository } from './token_respository';
import { UploadImageUrlRepository } from './upload_image_url';

export type Repositories = {
  "auth": AuthRepository;
  "token": TokenRepository;
  "insuranceContractor": InsuranceContractorRepository;
  "uploadImageUrl": UploadImageUrlRepository;
};

const injectKey = Symbol("repos") as InjectionKey<Repositories>

export const provideRepositories = (app: App, repositories: Repositories): void => {
  app.provide(injectKey, repositories);
  return;
}

export const useRepositories = (): Repositories => {
  const target = inject(injectKey);
  if (!target) {
    throw new Error(`repos is not provided`);
  }
  return target;
}