import { createApp } from 'vue'
import './styles/main.scss';
import router from './router';
import App from './App.vue';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { provideRepositories } from './repository/repositories';
import APIAuthRepository from './infra/api/api_auth_repository';
import LocalStorageTokenRepository from './infra/local-storage/local_storage_token_repository';
import { authState, initAuthState } from './store/auth_state_store';
import APIInsuranceContractorRepository from './infra/api/api_insurance_contractor_repository';
import APIUploadImageUrlRepository from './infra/api/api_upload_image_url_repository';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const init = async (): Promise<void> =>{
  const tokenRepo = new LocalStorageTokenRepository();
  const hasToken  = await tokenRepo.get() !== null;

  initAuthState(hasToken);
  if (authState === undefined) {
    console.error("auth state is not init");
    return;
  }

  const app = createApp(App);
  provideRepositories(app, {
    "auth": new APIAuthRepository(
      authState,
      {
        baseURL: process.env.VUE_APP_API_BASE_URL,
      },
    ),
    "token": tokenRepo,
    "insuranceContractor": new APIInsuranceContractorRepository(
      authState,
      {
        baseURL: process.env.VUE_APP_API_BASE_URL,
      },
    ),
    "uploadImageUrl": new APIUploadImageUrlRepository(
      authState,
      {
        baseURL: process.env.VUE_APP_API_BASE_URL,
      },
    )
  })

  Sentry.init({
    app,
    dsn: "https://1132b5571fa34bb0a8b647cf976996fa@o344019.ingest.sentry.io/6232790",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost:8090", "haruto-coating-plus.wirelessgate.net", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });

  app.use(ElementPlus).use(router).mount('#app');
  // app.use(router).mount('#app');
}

init();
