export class AuthenticationError {}

export class TokenNotFoundError {}

export class TokenRefreshError {
  error?: Error;
  constructor(err?: Error) {
    this.error = err;
  }
}

export class NotFoundError {}

export class DuplicateValueError {}

export class FileSizeLimitError {}

// エラーレスポンスを扱う共通クラス
export class FailedResult extends Error {
  code?: number;

  constructor(args: Partial<FailedResult>) {
    super(args.message);
    this.code = args.code;
  }
}
