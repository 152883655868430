import { Token } from "@/models/token";
import { TokenRepository } from "@/repository/token_respository";

const localStorageTokenKey = "token";

export default class LocalStorageTokenRepository implements TokenRepository {
  get(): Promise<Token | null> {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem(localStorageTokenKey);
      if (token === null) {
        resolve(null);
        return;
      }
      resolve(JSON.parse(token));
    });
  }

  delete(): Promise<void> {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem(localStorageTokenKey);
      if (token === null) {
        resolve();
        return;
      }
      localStorage.removeItem(localStorageTokenKey);
      resolve();
    });
  }

  save(token: Token): Promise<void> {
    return new Promise((resolve, reject) => {
      localStorage.setItem(localStorageTokenKey, JSON.stringify(token));
      resolve();
    });
  }
}
